@import './navigation';
@import './home';
@import './openformusic';
@import './eventcal';
@import './musiccal.scss';
@import './foodmenu';
@import './contact';
@import './calendar';
@import './mediaQueries';
@import './shop';
@import './loader';
@import './instagramFeed';
@import './coronaVirus';

* {
    font-family: 'Lato', sans-serif !important;
}

html {
    scroll-behavior: smooth;
    
}
