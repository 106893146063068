#menu {
    header {
        background-color: #212121;
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
      }
      img {
          height: 60%;
      }
      p {
          text-align: center;
      }
    .flex-container {
        display: flex;
        justify-content: center;
    }
    .toastButton {
        margin-top: 30px;
    }
}


#foodmenu {
    background-color: gray;
    
    p {
        text-align: center !important;
    }

}

#drinks, #brunch, #smallPlates, #dinner{
    padding-bottom: 100px;
    text-align: center;

    h3 {
        text-align: center;
        background-color: rgba(0, 0, 0, 0.048);
    }

    .cocktailList, .brunchList, .plateList, .dinnerList {
        margin: auto 250px;
    }

    p {
        font-size: 22px;
        margin-bottom: 2px;
    }

    span {
        font-size: 13px;
        color: gray;
        font-style: italic;
    }

    
}



