@media only screen and (max-width: 425px) {
    #navigation {
        a {
            font-size: 12px;
            margin: 12px !important;
        }
    }
    
    #home {
        height: 600px;

        h6 {
            font-size: 10px !important;
            margin: 0px;
        }
    }

    #coronaVirus {
        
        p {
            font-size: 12px;
            text-align: center;
        }
        div.description {
            width: 85%;
            padding: 0px !important; 
            margin: 0px !important;
            text-align: center;
            
        }

    }
    
    #openForMusic { 
        padding-bottom: 50px;
        padding-top: 15px;

        p {
            margin: 30px 15px;
            font-size: 16px;
        }

        // div.image {
        //     max-height: 55px;
        // }

        img {
            width: 80%;
            max-height: 60px;
        }
    

        
        h6 {
            font-size: 12px !important;
            margin: 0px;
        }
    }


    #drinks, #brunch, #smallPlates, #dinner {
        .cocktailList, .plateList, .brunchList, .dinnerList {
            margin: auto 30px;
        }
    }

   

    #contact {
        .flexContainer {
            flex-direction: column !important;
            justify-content: center;
        }
        
        .info {
            margin: 20px 15px;
            text-align: center;
        }

        iframe {
            width: 95%;
            margin: 20px 10px;
        }
    }

    #loader {
        
        svg {
            
            margin-top: 50px !important;
            padding: 75px !important;
        }
    }

    div.musicEventDiv {
        position: relative;
        bottom: 600px !important;
    }

    div.eventEventDiv {
        position: relative;
        bottom: 600px !important;
    }

    #shop {
        .checkout-state {
            width: 30% !important;
        }

    }

}

@media only screen and (max-width: 360px) {
    #navigation {
        a {
            font-size: 12px;
            margin: 5px 5px 5px 18px !important;
        }
    }

    #loader {
        
        svg {
            
            margin-top: 50px !important;
            padding: 75px !important;
        }
    }
}

@media only screen and (max-width: 320px) {
    #navigation {
        a {
            font-size: 12px;
            margin: 5px 5px 5px 9px !important;
        }
    }

    #loader {
        

        svg {
            
            margin-top: 50px !important;
            padding: 75px !important;
        }
    }
    
}