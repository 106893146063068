#eventcal {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.048);
}

.calendar {
    margin-bottom: 60px;
    
}



