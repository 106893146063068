#calendars {
    
    .calendar {
        margin: 0px 40px 70px 40px;
    }
}

h1 {
    font-size: 15px;
}

h2 {
    font-size: 25px;
}

h3 {
    font-size: 25px;
}

.flexContainer {
    display: flex;
    justify-content: center;
}

.musicEventDiv {
    margin: 10px;
    padding: 10px;
    background-color: gray;
    border: 2px solid rgba(75, 73, 73, 0.5);
    border-radius: 10px;
    color: white;
    width: 50%;
    position: relative; 
    bottom: 500px;
    z-index: 100;
    box-shadow: 3px 3px #c4c2c236;
}

.eventEventDiv {
    margin: 10px;
    padding: 10px;
    background-color: gray;
    border: 2px solid black;
    border-radius: 10px;
    color: white;
    width: 50%;
    position: relative; 
    bottom: 500px;
    z-index: 100;
}



div.rbc-event, div.rbc-event.rbc-selected {
    background-color: gray;
}

div.rbc-event {
    background-color: gray;
    font-size: 11px;
}

.rbc-toolbar span.rbc-toolbar-label {
    text-align: right;
}

@media only screen and (max-width: 400px) {
    #calendars {
        height: 500px;
        div.App.calendar:nth-child(2) div.rbc-calendar{
            height: 420px !important;
        }
    }
}

@media only screen and (max-width: 411px) {
    #calendars {
        height: 660px;
        
        div.App.calendar {
            height: 800px;
        }
        
        div.rbc-calendar{
            height: 540px !important;
        }

        div.rbc-month-view {
            height: 540px;
        }

        div.rbc-month-row, div.rbc-row-bg, div.rbc-row-content {
            height: 300px;
        }

        .rbc-toolbar button {
            padding: .375rem 0.5rem !important;
        }
        
    }
}