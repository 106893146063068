#navigation a {
  color: white;
  text-decoration: none;
  margin: 20px;
}
#navigation img {
  height: 30px;
}

#home {
  height: 800px;
  background-image: url("images/gramophone_ivory.jpg");
  background-position: right top;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #161B1E;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#home #roxylogo {
  background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.65) 46%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.65) 46%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.65) 46%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#a6000000", endColorstr="#00000000",GradientType=1 );
  border-radius: 15px;
  animation: draw;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-direction: forwards;
  animation-fill-mode: normal;
  padding: 20px 10px;
  height: 230px;
  width: 370px;
}
#home .flexContainer {
  display: flex;
  justify-content: center;
}
#home #restaurantGuru {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  top: 70px;
  padding-right: 25px;
  opacity: 95%;
}
#home h6 {
  color: white;
  font-size: 24px;
  position: relative;
  top: 70px;
  text-align: center;
}
#home a {
  text-decoration: none;
  color: white;
}
@keyframes draw {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
  100% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
}

#openForMusic {
  background-image: url("images/wood_texture.jpg");
  background-size: cover;
  padding-bottom: 75px;
  padding-top: 25px;
}
#openForMusic .flex-container {
  display: flex;
  justify-content: center;
}
#openForMusic img {
  width: 40%;
  height: 100%;
  margin: 20px auto;
  align-self: center;
}
#openForMusic p {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 25px;
  margin: 25px 150px;
  text-align: center;
  background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 46%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 46%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 46%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#a6000000", endColorstr="#00000000",GradientType=1 );
}
#eventcal {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.048);
}

.calendar {
  margin-bottom: 60px;
}

#musiccal {
  background-color: rgba(0, 0, 0, 0.048);
  text-align: center;
}

#menu header {
  background-color: #212121;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
#menu img {
  height: 60%;
}
#menu p {
  text-align: center;
}
#menu .flex-container {
  display: flex;
  justify-content: center;
}
#menu .toastButton {
  margin-top: 30px;
}

#foodmenu {
  background-color: gray;
}
#foodmenu p {
  text-align: center !important;
}

#drinks, #brunch, #smallPlates, #dinner {
  padding-bottom: 100px;
  text-align: center;
}
#drinks h3, #brunch h3, #smallPlates h3, #dinner h3 {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.048);
}
#drinks .cocktailList, #drinks .brunchList, #drinks .plateList, #drinks .dinnerList, #brunch .cocktailList, #brunch .brunchList, #brunch .plateList, #brunch .dinnerList, #smallPlates .cocktailList, #smallPlates .brunchList, #smallPlates .plateList, #smallPlates .dinnerList, #dinner .cocktailList, #dinner .brunchList, #dinner .plateList, #dinner .dinnerList {
  margin: auto 250px;
}
#drinks p, #brunch p, #smallPlates p, #dinner p {
  font-size: 22px;
  margin-bottom: 2px;
}
#drinks span, #brunch span, #smallPlates span, #dinner span {
  font-size: 13px;
  color: gray;
  font-style: italic;
}

#contact {
  color: white;
  background-image: url("images/barrels.jpg");
  background-size: cover;
  padding-bottom: 100px;
  padding-top: 1px;
}
#contact header {
  background-color: #212121;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
#contact img {
  height: 60%;
}
#contact h2 {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.322);
  padding: 5px;
}
#contact p {
  margin: 0px;
}
#contact a {
  text-decoration: none;
  color: white;
}
#contact .flexContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}
#contact .contacts {
  position: relative;
  top: 43px;
  margin-left: 10px;
}
#contact .contactTitle {
  margin-top: 10px;
}
#contact .emailLink:hover {
  color: #5092E4;
}
#contact .contactLink {
  position: relative;
  left: 10px;
}
#contact div.info {
  padding: 20px;
  background: #2121218f;
  border-radius: 10px;
}
#contact iframe {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* Firefox 10+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(99%);
  /* Chrome 19+ & Safari 6+ */
  -webkit-backface-visibility: hidden;
  /* Fix for transition flickering */
  border: 0;
  border-radius: 10px;
  opacity: 0.95;
}
#contact button {
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.384);
  margin-top: 20px;
  padding: 5px;
  width: 60%;
  font-size: 14px;
  background-color: #FFFFFF;
}
#contact button:hover {
  border: 2px solid #212121;
  background-color: #e0e0dd;
}

#calendars .calendar {
  margin: 0px 40px 70px 40px;
}

h1 {
  font-size: 15px;
}

h2 {
  font-size: 25px;
}

h3 {
  font-size: 25px;
}

.flexContainer {
  display: flex;
  justify-content: center;
}

.musicEventDiv {
  margin: 10px;
  padding: 10px;
  background-color: gray;
  border: 2px solid rgba(75, 73, 73, 0.5);
  border-radius: 10px;
  color: white;
  width: 50%;
  position: relative;
  bottom: 500px;
  z-index: 100;
  box-shadow: 3px 3px #c4c2c236;
}

.eventEventDiv {
  margin: 10px;
  padding: 10px;
  background-color: gray;
  border: 2px solid black;
  border-radius: 10px;
  color: white;
  width: 50%;
  position: relative;
  bottom: 500px;
  z-index: 100;
}

div.rbc-event, div.rbc-event.rbc-selected {
  background-color: gray;
}

div.rbc-event {
  background-color: gray;
  font-size: 11px;
}

.rbc-toolbar span.rbc-toolbar-label {
  text-align: right;
}

@media only screen and (max-width: 400px) {
  #calendars {
    height: 500px;
  }
  #calendars div.App.calendar:nth-child(2) div.rbc-calendar {
    height: 420px !important;
  }
}
@media only screen and (max-width: 411px) {
  #calendars {
    height: 660px;
  }
  #calendars div.App.calendar {
    height: 800px;
  }
  #calendars div.rbc-calendar {
    height: 540px !important;
  }
  #calendars div.rbc-month-view {
    height: 540px;
  }
  #calendars div.rbc-month-row, #calendars div.rbc-row-bg, #calendars div.rbc-row-content {
    height: 300px;
  }
  #calendars .rbc-toolbar button {
    padding: 0.375rem 0.5rem !important;
  }
}
@media only screen and (max-width: 425px) {
  #navigation a {
    font-size: 12px;
    margin: 12px !important;
  }

  #home {
    height: 600px;
  }
  #home h6 {
    font-size: 10px !important;
    margin: 0px;
  }

  #coronaVirus p {
    font-size: 12px;
    text-align: center;
  }
  #coronaVirus div.description {
    width: 85%;
    padding: 0px !important;
    margin: 0px !important;
    text-align: center;
  }

  #openForMusic {
    padding-bottom: 50px;
    padding-top: 15px;
  }
  #openForMusic p {
    margin: 30px 15px;
    font-size: 16px;
  }
  #openForMusic img {
    width: 80%;
    max-height: 60px;
  }
  #openForMusic h6 {
    font-size: 12px !important;
    margin: 0px;
  }

  #drinks .cocktailList, #drinks .plateList, #drinks .brunchList, #drinks .dinnerList, #brunch .cocktailList, #brunch .plateList, #brunch .brunchList, #brunch .dinnerList, #smallPlates .cocktailList, #smallPlates .plateList, #smallPlates .brunchList, #smallPlates .dinnerList, #dinner .cocktailList, #dinner .plateList, #dinner .brunchList, #dinner .dinnerList {
    margin: auto 30px;
  }

  #contact .flexContainer {
    flex-direction: column !important;
    justify-content: center;
  }
  #contact .info {
    margin: 20px 15px;
    text-align: center;
  }
  #contact iframe {
    width: 95%;
    margin: 20px 10px;
  }

  #loader svg {
    margin-top: 50px !important;
    padding: 75px !important;
  }

  div.musicEventDiv {
    position: relative;
    bottom: 600px !important;
  }

  div.eventEventDiv {
    position: relative;
    bottom: 600px !important;
  }

  #shop .checkout-state {
    width: 30% !important;
  }
}
@media only screen and (max-width: 360px) {
  #navigation a {
    font-size: 12px;
    margin: 5px 5px 5px 18px !important;
  }

  #loader svg {
    margin-top: 50px !important;
    padding: 75px !important;
  }
}
@media only screen and (max-width: 320px) {
  #navigation a {
    font-size: 12px;
    margin: 5px 5px 5px 9px !important;
  }

  #loader svg {
    margin-top: 50px !important;
    padding: 75px !important;
  }
}
#shop .shop-logo {
  height: 60%;
}
#shop .App-header {
  background-color: #212121;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
#shop .App-header-text {
  font-size: 16px;
}
#shop .App-shop {
  padding: 1rem 1rem 3rem;
}
#shop .App-products {
  margin: 3rem auto 3.5rem;
  max-width: 800px;
  text-align: center;
}
@media (min-width: 800px) {
  #shop .App-products {
    display: flex;
    justify-content: center;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#shop .Product {
  border: 1px solid #eee;
  margin: 1rem 0;
  padding: 1rem;
}
#shop .Product img {
  height: 130px;
}
@media (min-width: 800px) {
  #shop .Product {
    margin: 0 1rem;
    width: 33.3333%;
  }
}
#shop .Product-title {
  font-size: 16px;
}
#shop .Product-price {
  margin-bottom: 1rem;
}
#shop .Product-buy-button {
  display: inline-block;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  border: 0;
  outline: 0;
  border-radius: 100em;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  background-color: #212121;
  color: #fff;
  cursor: pointer;
  transition-property: background-color, color;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  -webkit-appearance: none;
}
#shop .Product-buy-button:hover,
#shop .Product-buy-button:focus {
  background-color: #212121bd;
}
#shop .Cart {
  border-top: 1px solid #eee;
  margin: 0 auto;
  max-width: 800px;
  padding-top: 2rem;
  text-align: center;
}
#shop .Cart-total-cost {
  font-weight: 700;
  padding: 2rem;
}
#shop .CartItem {
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  text-align: left;
}
#shop .CartItem-details {
  display: flex;
  width: 180px;
}
#shop .CartItem-details div {
  flex: 1 1 50%;
}
#shop .CartItem-quantity {
  padding: 0 0.5rem;
}
#shop .delete-item {
  color: #642830;
}
#shop .CheckoutForm {
  border-top: 1px solid #eee;
  margin: 0 auto;
  max-width: 800px;
  padding-top: 2rem;
  text-align: center;
}
#shop .CheckoutForm-complete {
  color: #642930;
  font-weight: 700;
  text-align: center;
}
#shop .CheckoutForm-button {
  display: inline-block;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  border: 0;
  outline: 0;
  border-radius: 100em;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  background-color: #642930;
  color: #fff;
  cursor: pointer;
  transition-property: background-color, color;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  -webkit-appearance: none;
}
#shop .CheckoutForm-button:hover,
#shop .CheckoutForm-button:focus {
  background-color: #642930da;
}
#shop .CheckoutForm-button:disabled {
  background-color: #ccc;
}
#shop .CheckoutForm-error {
  color: #dc4545;
}
#shop .StripeElement {
  display: block;
  margin: 0.5rem auto 1.5rem;
  max-width: 500px;
  padding: 12px 16px;
  font-size: 1rem;
  border: 1px solid #eee;
  border-radius: 3px;
  outline: 0;
  background: white;
}
#shop .checkout-input {
  width: 100%;
  max-width: 500px;
  padding: 12px 16px;
  margin: 8px;
  font-size: 1rem;
  border: 1px solid #eee;
  border-radius: 3px;
  outline: 0;
  background: white;
}
#shop .checkout-city {
  width: 50%;
  max-width: 242px;
  padding: 12px 16px;
  margin: 8px;
  font-size: 1rem;
  border: 1px solid #eee;
  border-radius: 3px;
  outline: 0;
  background: white;
}
#shop .checkout-state {
  width: 10%;
  max-width: 75px;
  padding: 12px 16px;
  margin: 8px;
  font-size: 1rem;
  border: 1px solid #eee;
  border-radius: 3px;
  outline: 0;
  background: white;
}
#shop .checkout-zip {
  width: 40%;
  max-width: 150px;
  padding: 12px 16px;
  margin: 8px;
  font-size: 1rem;
  border: 1px solid #eee;
  border-radius: 3px;
  outline: 0;
  background: white;
}

#loader {
  height: 600px;
  display: flex;
  justify-content: center;
}
#loader svg {
  height: 70%;
  margin-top: 50px;
  padding: 150px;
  animation: loaderFadeOut;
  animation-duration: 4.5s;
  animation-iteration-count: 1;
  animation-direction: forwards;
}
#loader .orb path {
  color: gray;
  stroke: gray;
  fill: gray;
  animation: loader;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-direction: forwards;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
    transform-origin: 59.2px 59.2px;
  }
  100% {
    transform: rotate(360deg);
    transform-origin: 59.2px 59.2px;
  }
}
@keyframes loaderFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#insta .App-header {
  background-color: #212121;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
#insta img {
  height: 60%;
}

#instagramFeed {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#instagramFeed div.posts {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#instagramFeed img {
  width: 350px;
  height: 350px;
  padding: 5px;
}

#coronaVirus {
  background-color: #EEE9DA;
  background-size: cover;
  padding-bottom: 75px;
  padding-top: 25px;
}
#coronaVirus .flex-container {
  display: flex;
  justify-content: center;
}
#coronaVirus img {
  width: 50%;
  height: 100%;
  margin: 20px auto;
  align-self: center;
}
#coronaVirus div.description {
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 25px;
  margin: 25px 150px;
  text-align: center;
  background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 26%, rgba(0, 0, 0, 0.1) 100%);
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 26%, rgba(0, 0, 0, 0.1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.1) 26%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#a6000000", endColorstr="#00000000",GradientType=1 );
}

* {
  font-family: "Lato", sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

