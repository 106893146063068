
#navigation {

    a {
        color: white;
        text-decoration: none;
        
        margin: 20px;
    }

    img {
        height: 30px;
    }

    

}



