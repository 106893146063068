#loader {
    height: 600px;
    display: flex;
    justify-content: center;

    svg {
        height: 70%;
        margin-top: 50px;
        padding: 150px;
        animation: loaderFadeOut;
        animation-duration: 4.5s;
        animation-iteration-count: 1;
        animation-direction: forwards;
    }

    .orb path {
        color: gray;
        stroke: gray;
        fill: gray;
        animation: loader;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-direction: forwards;
        
        
    }

    @keyframes loader {
        0% {    
            transform: rotate(0deg);
            transform-origin: 59.2px 59.2px;
        }
        
        
        100% {
            
            transform: rotate(360deg);
            transform-origin: 59.2px 59.2px;
        }
    }

    @keyframes loaderFadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    
}