#shop {

  .shop-logo {
    
    height: 60%;
  }
  
  .App-header {
    background-color: #212121;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-header-text {
    font-size: 16px;
  }
  
  .App-shop {
    padding: 1rem 1rem 3rem;
  }
  
  .App-products {
    margin: 3rem auto 3.5rem;
    max-width: 800px;
    text-align: center;
  }
  
  @media (min-width: 800px) {
    .App-products {
      display: flex;
      justify-content: center;
    }
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .Product {
    border: 1px solid #eee;
    margin: 1rem 0;
    padding: 1rem;

    img {
      height: 130px;
    }
  }
  
  @media (min-width: 800px) {
    .Product {
      margin: 0 1rem;
      width: 33.3333%;
    }
  }
  
  .Product-title {
    font-size: 16px;
  }
  
  .Product-price {
    margin-bottom: 1rem;
  }
  
  .Product-buy-button {
    display: inline-block;
    margin: 0 0 1rem 0;
    padding: 0.85em 1em;
    border: 0;
    outline: 0;
    border-radius: 100em;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    background-color: #212121;
    color: #fff;
    cursor: pointer;
    transition-property: background-color, color;
    transition-duration: 0.25s;
    transition-timing-function: ease-out;
    -webkit-appearance: none;
  }
  
  .Product-buy-button:hover,
  .Product-buy-button:focus {
    background-color: #212121bd;
  }

  .Cart {
    border-top: 1px solid #eee;
    margin: 0 auto;
    max-width: 800px;
    padding-top: 2rem;
    text-align: center;
  }
  
  .Cart-total-cost {
    font-weight: 700;
    padding: 2rem;
  }

  .CartItem {
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    text-align: left;
  }
  
  .CartItem-details {
    display: flex;
    width: 180px;
  }
  
  .CartItem-details div {
    flex: 1 1 50%;
  }
  
  .CartItem-quantity {
    padding: 0 0.5rem;
  }

  .delete-item {
    color: #642830;
  }

  .CheckoutForm {
    border-top: 1px solid #eee;
    margin: 0 auto;
    max-width: 800px;
    padding-top: 2rem;
    text-align: center;
  }
  
  .CheckoutForm-complete {
    color: #642930;
    font-weight: 700;
    text-align: center;
  }
  
  .CheckoutForm-button {
    display: inline-block;
    margin: 0 0 1rem 0;
    padding: 0.85em 1em;
    border: 0;
    outline: 0;
    border-radius: 100em;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    background-color: #642930;
    color: #fff;
    cursor: pointer;
    transition-property: background-color, color;
    transition-duration: 0.25s;
    transition-timing-function: ease-out;
    -webkit-appearance: none;
  }
  
  .CheckoutForm-button:hover,
  .CheckoutForm-button:focus {
    background-color: #642930da;
  }
  
  .CheckoutForm-button:disabled {
    background-color: #ccc;
  }
  
  .CheckoutForm-error {
    color: #dc4545;
  }
  
  .StripeElement {
    display: block;
    margin: 0.5rem auto 1.5rem;
    max-width: 500px;
    padding: 12px 16px;
    font-size: 1rem;
    border: 1px solid #eee;
    border-radius: 3px;
    outline: 0;
    background: white;
  }

  .checkout-input {
    width: 100%;
    max-width: 500px;
    padding: 12px 16px;
    margin: 8px;
    font-size: 1rem;
    border: 1px solid #eee;
    border-radius: 3px;
    outline: 0;
    background: white;
  }

  .checkout-city {
    width: 50%;
    max-width: 242px;
    padding: 12px 16px;
    margin: 8px;
    font-size: 1rem;
    border: 1px solid #eee;
    border-radius: 3px;
    outline: 0;
    background: white;
  }

  .checkout-state {
    width: 10%;
    max-width: 75px;
    padding: 12px 16px;
    margin: 8px;
    font-size: 1rem;
    border: 1px solid #eee;
    border-radius: 3px;
    outline: 0;
    background: white;
  }

  .checkout-zip {
    width: 40%;
    max-width: 150px;
    padding: 12px 16px;
    margin: 8px;
    font-size: 1rem;
    border: 1px solid #eee;
    border-radius: 3px;
    outline: 0;
    background: white;
  }
  
}