#coronaVirus {
    // background-image: url('images/notebook_paper.png');
    background-color: #EEE9DA;
    background-size: cover;
    
    padding-bottom: 75px;
    padding-top: 25px;

    .flex-container {
        display: flex;
        justify-content: center;

    }

    img {
        width: 50%;
        height: 100%;
        margin: 20px auto;
        align-self: center;
    }

    div.description {
        color: black;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 25px;
        margin: 25px 150px;
        text-align: center;
        background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 26%, rgba(0,0,0,.1) 100%); /* FF3.6-15 */
        background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.2) 26%,rgba(0,0,0,.1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: radial-gradient(ellipse at center, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.1) 26%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 );
        
    }
}

