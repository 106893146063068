#home {
    height: 800px;
    background-image: url('images/gramophone_ivory.jpg');
    background-position: right top;
    background-size: cover;
    
    background-repeat: no-repeat;
    background-color: #161B1E;
    display: flex;
    flex-direction: column;
    justify-content: center;
    

    #roxylogo {
        // margin-top: 100px;
        // background-color: rgba(0, 0, 0, 0.5);
        background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.65) 46%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
        background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.65) 46%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: radial-gradient(ellipse at center, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.65) 46%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 );
        border-radius: 15px;
        // stroke-dashoffset: 5000;
        // stroke-dasharray: 5000;
        animation: draw;
        animation-duration: 3s;
        animation-iteration-count: 1;
        animation-direction: forwards;
        animation-fill-mode: normal;
        padding: 20px 10px;
        height: 230px;
        width: 370px;
    }

    .flexContainer {
        display: flex;
        justify-content: center;
    }

    #restaurantGuru {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: relative;
        top: 70px;
        padding-right: 25px;
        opacity: 95%;
    }
    
    h6 {
        
        color: white;
        font-size: 24px;
        position: relative; 
        top: 70px;
        text-align: center;
        
    }

    a {
        text-decoration: none;
        color: white;
    }

    @keyframes draw {
        0% {
            // stroke-dashoffset: -5000;
            fill-opacity: 0;
            stroke-opacity: 0;
        }
        // 50% {
        //     fill-opacity: 0.5;
        // }
        100% {
            // stroke-dashoffset: 0;
            fill-opacity: 1;
            stroke-opacity: 1;
        }
    }

    

}