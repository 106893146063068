#insta {
    .App-header {
        background-color: #212121;
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
      }

    img {
        height: 60%;
    }
}

#instagramFeed {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    div.posts {
        
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    img {
        width: 350px;
        height: 350px;
        padding: 5px;
    }
}