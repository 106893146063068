
#contact {
    
    color: white;
    background-image: url('images/barrels.jpg');
    
    background-size: cover;
    padding-bottom: 100px;
    padding-top: 1px;

    header {
        background-color: #212121;
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
      }
      img {
          height: 60%;
      }
    
    h2 {
        text-align: center;
        background-color: rgba(0, 0, 0, 0.322);
        padding: 5px;
    }

    p {
        margin: 0px;
    }

    a {
        text-decoration: none;
        color: white;
    }

    .flexContainer {
        display: flex;
        justify-content: space-evenly;
        margin-top: 20px;
    }

    .contacts {
        position: relative;
        top: 43px;
        margin-left: 10px;

    }

    .contactTitle {
        margin-top: 10px;
    }

    .emailLink:hover {
        color: #5092E4
    }

    .contactLink {
        position: relative;
        left: 10px
    }

    div.info {
        padding: 20px;
        background: #2121218f;
        border-radius: 10px;
    }

    iframe {
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+ */
        filter: gray; /* IE6-9 */
        -webkit-filter: grayscale(99%); /* Chrome 19+ & Safari 6+ */
        -webkit-backface-visibility: hidden;  /* Fix for transition flickering */
        border: 0;
        border-radius: 10px;
        opacity: 0.95;
    }

    button {
        border-radius: 5px;
        border: 2px solid rgba(0, 0, 0, 0.384);
        margin-top: 20px;
        padding: 5px;
        width: 60%;
        font-size: 14px;
        background-color: #FFFFFF;
    }

    button:hover {
        border: 2px solid #212121;
        background-color: rgb(224, 224, 221);
    }
    
   
}